import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrderCompletedTimer from './OrderCompletedTimer';
import { updateOrderCompletedTime } from '../actions';

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.handleOnClickFuc = this.handleOnClickFuc.bind(this);
  }

  handleOnClickFuc() {
    const {
      order: { _id, createdTime, completedTime, lines },
    } = this.props;

    this.props.updateOrderCompletedTime({
      _id,
      createdTime,
      completedTime,
      lines,
    });
  }

  renderLines() {
    const { lineItems } = this.props.order;

    return lineItems.elements.map(({ name, id, note, modifications }) => {
      const mods =
        modifications &&
        modifications.elements.map(({ id, name }) => {
          return (
            <li key={id} className="more-indent">
              {name}
            </li>
          );
        });

      return (
        <ul key={id}>
          <li>{name}</li>
          <li className="indent">{note}</li>
          {mods}
        </ul>
      );
    });
  }

  render() {
    const {
      icon,
      order: {
        title,
        completedTime,
        payments: {
          elements: [{ createdTime }],
        },
      },
      id,
      _id,
    } = this.props;

    return (
      <div id={id} className="card black">
        <div className="card-content white-text left-align">
          <div className="card-header">
            <div className="card-title">ORDER {title}</div>
            <OrderCompletedTimer
              title={title}
              _id={_id}
              createdTime={createdTime}
              completedTime={completedTime}
            />
          </div>
          <button className="btn-floating btn-large fab grey">
            <i className="material-icons right" onClick={this.handleOnClickFuc}>
              {icon}
            </i>
          </button>
          {this.renderLines()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { _id } = ownProps;
  return {
    order: state.orders[_id],
  };
}

export default connect(mapStateToProps, {
  updateOrderCompletedTime,
})(OrderCard);
