import React, { Component } from 'react';
import { connect } from 'react-redux';

class OrderCompletedTimer extends Component {
  shouldComponentUpdate(nextProps) {
    return !nextProps.completedTime;
  }

  render() {
    const { createdTime, completedTime, curTime } = this.props;

    const spentTime = completedTime
      ? (
          (new Date(completedTime) - new Date(createdTime).getTime()) /
          (60 * 1000)
        ).toFixed(2)
      : ((curTime - new Date(createdTime).getTime()) / (60 * 1000)).toFixed(2);

    return (
      <div
        id="order-spent-time"
        className={
          spentTime > 6 ? (spentTime > 10 ? 'black-text' : 'red-text') : null
        }
      >
        {spentTime} mins
      </div>
    );
  }
}

function mapStateToProps({ curTime }) {
  return {
    curTime,
  };
}

export default connect(mapStateToProps)(OrderCompletedTimer);
