import { FETCH_REPORT_RES } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_REPORT_RES:
      return action.payload.filter((order) => order.completedTime);
    default:
      return state;
  }
}
