import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import SigninField from './SigninField';
import { connect } from 'react-redux';
import { signin } from '../../actions';

class Signin extends Component {
  onSubmit = (formProps) => {
    this.props.signin(formProps, () => this.props.history.push('/'));
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <h5>Sign in to continue to Dev Portal</h5>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            id="name"
            label="Username"
            name="email"
            type="text"
            autoComplete="none"
            component={SigninField}
          />
          <Field
            id="pass"
            label="Password"
            name="password"
            type="password"
            autoComplete="none"
            component={SigninField}
          />
          <button className="btn">
            Sign In<i className="material-icons right">send</i>
          </button>
          <div>{this.props.errorMessage}</div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.errorMessage };
}

export default reduxForm({ form: 'signin' })(
  connect(mapStateToProps, { signin })(Signin)
);
