import {
  FETCH_ORDERS_RES,
  UPDATE_ORDER_COMPLETED_TIME,
  RECEIVE_WS_ORDERS,
  UPDATE_LINE_BREW_READY_TIME,
  UPDATE_LINE_BREW_USED_TIME,
} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_ORDERS_RES:
    case RECEIVE_WS_ORDERS:
      const newState = {};
      for (let order of action.payload) {
        const items = [...order.lineItems.elements];

        for (let item of items) {
          if (item.refunded) {
            items.splice(items.indexOf(item), 1);
          }
        }

        newState[order._id] = { ...order, lineItems: { elements: items } };
      }

      return { ...state, ...newState };
    case UPDATE_ORDER_COMPLETED_TIME:
      const { _id, completedTime } = action.payload;
      return { ...state, [_id]: { ...state[_id], completedTime } };
    case UPDATE_LINE_BREW_READY_TIME:
    case UPDATE_LINE_BREW_USED_TIME:
      const { orderId, line } = action.payload;

      const numOfLines = state[orderId].lineItems.elements.length;
      let newLines;
      if (numOfLines === 1) {
        newLines = [{ ...line }];
      } else {
        const index = state[orderId].lineItems.elements.findIndex(
          (el) => el.id === line.id
        );
        newLines = [
          ...state[orderId].lineItems.elements.slice(0, index),
          { ...line },
          ...state[orderId].lineItems.elements.slice(index + 1),
        ];
      }

      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          lineItems: {
            elements: newLines,
          },
        },
      };
    default:
      return state;
  }
}
