import React, { Component } from 'react';
import requireAuth from './requireAuth';
import OrderCard from './OrderCard';
// import Loading from './Loading';
import { connect } from 'react-redux';
import {
  fetchOrders,
  updateOrderCompletedTime,
  receiveWebSocketOrders,
  startTimer,
  stopTimer,
} from '../actions';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.handleCardBtnClick = this.handleCardBtnClick.bind(this);
  }

  ws = new WebSocket(process.env.REACT_APP_WS_URL);

  handleCardBtnClick(order) {
    this.props.updateOrderCompletedTime(order);
  }

  componentDidMount() {
    this.props.startTimer();

    const today = (outPut = 1, subHour = 0) => {
      const d = new Date();
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      const hour = `0${d.getHours() - subHour}`.slice(-2);
      const year = d.getFullYear();

      let result;
      if (outPut === 1) {
        result = `${year}-${month}-${day}T00:00:00`;
      } else if (outPut === 2) {
        result = `${year}-${month}-${day}T${hour}:00:00`;
      }

      return result;
    };

    this.props.fetchOrders(today(1));

    this.ws.onmessage = (msg) => {
      if (!['connected', 'ping'].includes(msg.data)) {
        this.props.receiveWebSocketOrders(JSON.parse(msg.data));
      }
      this.ws.send('pong');
    };
  }

  componentWillUnmount() {
    this.props.stopTimer();
  }

  renderOrders(sortedQueuedOrders, sortedCompletedOrders) {
    const queuedOrderList = sortedQueuedOrders.map(({ id, _id }) => {
      return <OrderCard id={id} _id={_id} key={_id} icon="add" />;
    });

    const completedOrderList = sortedCompletedOrders.map(({ id, _id }) => {
      return <OrderCard id={id} _id={_id} key={_id} icon="remove" />;
    });

    return (
      <div className="row">
        <div className="col s12 m6">
          <div className="scrollbar">{queuedOrderList}</div>
        </div>
        <div className="col s12 m6">
          <div className="scrollbar">{completedOrderList}</div>
        </div>
      </div>
    );
  }

  render() {
    const { orders } = this.props;
    const queuedOrders = [];
    const completedOrders = [];

    const ordersArr = Object.keys(orders);

    for (let i = 0; i < ordersArr.length; i++) {
      const { completedTime } = orders[ordersArr[i]];
      if (completedTime) {
        completedOrders.push({ ...orders[ordersArr[i]] });
      } else {
        queuedOrders.push({ ...orders[ordersArr[i]] });
      }
    }

    queuedOrders.sort((a, b) =>
      a.payments.elements[a.payments.elements.length - 1].createdTime >
      b.payments.elements[b.payments.elements.length - 1].createdTime
        ? 1
        : -1
    );

    completedOrders.sort((a, b) =>
      a.completedTime < b.completedTime ? 1 : -1
    );

    let curTimePerDrink;
    let preTimePerDrink;

    const getTimePerDrink = (order) => {
      const {
        lineItems,
        completedTime,
        payments: {
          elements: [{ createdTime }],
        },
      } = order;

      const timeSpent = (
        (new Date(completedTime) - new Date(createdTime).getTime()) /
        (60 * 1000)
      ).toFixed(2);

      const timePerDrink = timeSpent / lineItems.elements.length;

      return timePerDrink.toFixed(2);
    };

    const numOfCompletedOrders = Object.keys(completedOrders).length;

    if (numOfCompletedOrders >= 1) {
      curTimePerDrink = getTimePerDrink(completedOrders[0]);
    }

    if (numOfCompletedOrders >= 2) {
      preTimePerDrink = getTimePerDrink(completedOrders[1]);
    }

    let queuedDrinks = 0;

    queuedOrders.forEach(
      (order) => (queuedDrinks += order.lineItems.elements.length)
    );

    return (
      <div>
        <div id="curtime-per-drink">
          <strong>CURRENT TIME PER DRINK:</strong>{' '}
          <span
            className={
              curTimePerDrink >= preTimePerDrink ? 'red-text' : 'green-text'
            }
          >
            {curTimePerDrink ? `${curTimePerDrink} mins` : ''}
          </span>
        </div>

        <div className="row">
          <div className="col s12 m6">
            <h5>ORDERS IN QUEUE</h5>
            <div className="left">
              <strong>TOTAL DRINKS:</strong> {queuedDrinks}
            </div>
            <br />
            <div className="left">
              <strong>EST. TIME REMAINING:</strong>{' '}
              {curTimePerDrink
                ? `${(curTimePerDrink * queuedDrinks).toFixed(2)} mins`
                : ''}
            </div>
          </div>
          <div className="col s12 m6">
            <h5>COMPLETED ORDERS</h5>
          </div>
        </div>
        {this.renderOrders(queuedOrders, completedOrders)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.orders,
    loading: state.loading.fetch_orders,
  };
}

export default connect(mapStateToProps, {
  fetchOrders,
  updateOrderCompletedTime,
  receiveWebSocketOrders,
  startTimer,
  stopTimer,
})(requireAuth(OrderList));
