import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import orders from './orders';
import loading from './loading';
import sidenav from './sidenav';
import curTime from './curTime';
import report from './report';

export default combineReducers({
  auth,
  form: formReducer,
  orders,
  loading,
  sidenav,
  curTime,
  report,
});
