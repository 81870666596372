import React, { Component } from 'react';
import requireAuth from './requireAuth';
import BrewCard from './BrewCard';
import { connect } from 'react-redux';
import {
  fetchOrders,
  receiveWebSocketOrders,
  startTimer,
  stopTimer,
} from '../actions';

class BrewList extends Component {
  ws = new WebSocket(process.env.REACT_APP_WS_URL);

  componentDidMount() {
    this.props.startTimer();
    const today = (outPut = 1, subHour = 0) => {
      const d = new Date();
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      const hour = `0${d.getHours() - subHour}`.slice(-2);
      const year = d.getFullYear();

      let result;
      if (outPut === 1) {
        result = `${year}-${month}-${day}T00:00:00`;
      } else if (outPut === 2) {
        result = `${year}-${month}-${day}T${hour}:00:00`;
      }

      return result;
    };

    this.props.fetchOrders(today(1));

    this.ws.onmessage = (msg) => {
      if (!['connected', 'ping'].includes(msg.data)) {
        this.props.receiveWebSocketOrders(JSON.parse(msg.data));
      }
      this.ws.send('pong');
    };
  }

  componentWillUnmount() {
    this.props.stopTimer();
  }

  renderList(list, title, hasLeftButton, rightIcon) {
    const lines = list.map((line) => {
      const size = line.modifications && line.modifications.elements[0].name;

      const { createdTime, brewReadyTime, brewUsedTime } = line;

      return (
        <BrewCard
          key={line.id}
          orderId={line.orderId}
          title={line.orderNum}
          line={line}
          size={size}
          hasLeftButton={hasLeftButton}
          rightIcon={rightIcon}
          createdTime={createdTime}
          brewReadyTime={brewReadyTime}
          brewUsedTime={brewUsedTime}
        />
      );
    });

    return (
      <div className="col s12 m4">
        <h5>BREW {title}</h5>
        <div className="scrollbar">{lines}</div>
      </div>
    );
  }

  render() {
    const { orders } = this.props;
    const ordersArr = Object.keys(orders);

    const brewList = [];
    const brewReadyList = [];
    const brewUsedList = [];

    for (let i = 0; i < ordersArr.length; i++) {
      const { _id, title, payments } = orders[ordersArr[i]];
      for (let j = 0; j < orders[ordersArr[i]].lineItems.elements.length; j++) {
        const { brewReadyTime, brewUsedTime, name, item } = orders[
          ordersArr[i]
        ].lineItems.elements[j];

        if (name.includes('Slush') || !item) continue;

        let list = brewList;

        if (brewUsedTime) {
          list = brewUsedList;
        } else if (brewReadyTime) {
          list = brewReadyList;
        }

        list.push({
          ...orders[ordersArr[i]].lineItems.elements[j],
          orderId: _id,
          orderNum: title,
          createdTime:
            payments.elements[payments.elements.length - 1].createdTime,
        });
      }
    }

    brewList.sort((a, b) => (a.createdTime > b.createdTime ? 1 : -1));
    brewReadyList.sort((a, b) => (a.brewReadyTime > b.brewReadyTime ? 1 : -1));
    brewUsedList.sort((a, b) => (a.brewUsedTime < b.brewUsedTime ? 1 : -1));

    return (
      <div className="row">
        {this.renderList(brewList, 'LIST', false, 'add')}
        {this.renderList(brewReadyList, 'READY', true, 'add')}
        {this.renderList(brewUsedList, 'USED', false, 'remove')}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.orders,
  };
}

export default connect(mapStateToProps, {
  fetchOrders,
  receiveWebSocketOrders,
  startTimer,
  stopTimer,
})(requireAuth(BrewList));
