import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Sidenav from './components/Sidenav';
import Welcome from './components/Welcome';
import OrderList from './components/OrderList';
import Signin from './components/auth/Signin';
import Signout from './components/auth/Signout';
import BrewList from './components/BrewList';
import ReportSearch from './components/ReportSearch';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Sidenav />
        <Header />
        <main>
          <div className="center-align">
            <Route path="/" exact component={Welcome} />
            <Route path="/signin" exact component={Signin} />
            <Route path="/signout" exact component={Signout} />
            <Route path="/order-list" exact component={OrderList} />
            <Route path="/brew-list" exact component={BrewList} />
            <Route path="/report-search" exact component={ReportSearch} />
          </div>
        </main>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
