import { TIMER_START, TIMER_STOP, TIMER_TICK } from '../actions/types';

export default function (state = 0, action) {
  switch (action.type) {
    case TIMER_TICK:
      return new Date();
    case TIMER_START:
    case TIMER_STOP:
    default:
      return state;
  }
}
