import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import requireAuth from './requireAuth';
import { reduxForm, Field } from 'redux-form';
import ReportSearchField from './ReportSearchField';
import { connect } from 'react-redux';
import { fetchReport } from '../actions';

class ReportSearch extends Component {
  onSubmit = (formProps) => {
    const { date, fromTime, toTime } = formProps;

    const start = `${date}T${fromTime}`;
    const end = `${date}T${toTime}`;

    this.props.fetchReport(start, end);
  };

  getStats(orders) {
    let maxOrderTime = 0;
    let avgOrderTime = 0;
    let totalDrinks = 0;
    let totalOrders = 0;
    let totalOrderTime = 0;
    const thresholdArr = [];

    for (let order of orders) {
      const {
        completedTime,
        payments: {
          elements: [{ createdTime }],
        },
      } = order;

      const curOrderTime =
        (new Date(completedTime) - new Date(createdTime).getTime()) /
        (60 * 1000);

      totalOrderTime += curOrderTime;

      if (this.props.threshold && curOrderTime > this.props.threshold)
        thresholdArr.push(order);

      if (maxOrderTime < curOrderTime) maxOrderTime = curOrderTime;

      const items = [...order.lineItems.elements];

      for (let item of items) {
        console.log(item.name);
        if (item.refunded) {
          items.splice(items.indexOf(item), 1);
        }
      }

      totalDrinks += items.length;
    }

    totalOrders = orders.length;
    if (totalOrderTime) avgOrderTime = totalOrderTime / totalOrders;

    const thresholdBreakDown = {};
    let maxDrinks = 0;

    for (let order of thresholdArr) {
      const items = [...order.lineItems.elements];

      for (let item of items) {
        if (item.refunded) {
          items.splice(items.indexOf(item), 1);
        }
      }

      if (maxDrinks < items.length) maxDrinks = items.length;

      thresholdBreakDown[items.length] =
        thresholdBreakDown[items.length] + 1 || 1;
    }

    const x = [];
    const a = Array(maxDrinks)
      .fill(0)
      .map((_, i) => i + 1);

    const b = Array(maxDrinks).fill(0);

    for (let key in thresholdBreakDown) {
      x.push(
        <div key={key}>
          {thresholdBreakDown[key]} ORDERS --- {key} DRINK SET
        </div>
      );
      b.splice(key - 1, 1, thresholdBreakDown[key]);
    }

    console.log(b);

    const options = {
      xAxis: {
        type: 'category',
        data: a,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: b,
          type: 'bar',
          showBackground: true,
          smooth: true,
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
    };

    return (
      <div>
        <div>Total Drinks: {totalDrinks}</div>
        <div>Total Orders: {totalOrders}</div>
        <div>Max Order Time: {maxOrderTime.toFixed(2)}</div>
        <div>Avg Order Time: {avgOrderTime.toFixed(2)}</div>
        <div>Threshold Orders: {thresholdArr.length}</div>
        {x}
        <ReactECharts option={options} opts={{ renderer: 'svg' }} />
      </div>
    );
  }

  render() {
    const { handleSubmit, orders } = this.props;

    return (
      <div>
        <h5>Report</h5>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            id="date"
            label="Date"
            name="date"
            type="date"
            autoComplete="none"
            component={ReportSearchField}
          />
          <Field
            id="fromTime"
            label="From Time"
            name="fromTime"
            type="time"
            autoComplete="none"
            component={ReportSearchField}
          />
          <Field
            id="toTime"
            label="To Time"
            name="toTime"
            type="time"
            autoComplete="none"
            component={ReportSearchField}
          />
          <Field
            id="threshold"
            label="Threshold"
            name="threshold"
            type="number"
            autoComplete="none"
            component={ReportSearchField}
          />
          <button className="btn">
            Search<i className="material-icons right">search</i>
          </button>
        </form>
        {this.getStats(orders)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.report,
    threshold: state.form.report.values.threshold,
  };
}

const initialValues = { threshold: null };

export default reduxForm({ form: 'report', initialValues })(
  connect(mapStateToProps, { fetchReport })(requireAuth(ReportSearch))
);
