export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const FETCH_ORDERS_REQ = 'fetch_orders_req';
export const FETCH_ORDERS_RES = 'fetch_orders_res';
export const TOGGLE_SIDENAV = 'toggle_sidenav';
export const UPDATE_ORDER_COMPLETED_TIME = 'update_order_completed_time';
export const RECEIVE_WS_ORDERS = 'receive_ws_orders';
export const UPDATE_LINE_BREW_READY_TIME = 'update_line_brew_ready_time';
export const UPDATE_LINE_BREW_USED_TIME = 'update_line_brew_used_time';
export const TIMER_START = 'timer_start';
export const TIMER_TICK = 'timer_tick';
export const TIMER_STOP = 'timer_stop';
export const FETCH_REPORT_REQ = 'fetch_report_req';
export const FETCH_REPORT_RES = 'fetch_report_res';
