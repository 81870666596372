import {
  AUTH_USER,
  AUTH_ERROR,
  FETCH_ORDERS_REQ,
  FETCH_ORDERS_RES,
  TOGGLE_SIDENAV,
  UPDATE_ORDER_COMPLETED_TIME,
  RECEIVE_WS_ORDERS,
  UPDATE_LINE_BREW_READY_TIME,
  UPDATE_LINE_BREW_USED_TIME,
  TIMER_START,
  TIMER_TICK,
  TIMER_STOP,
  FETCH_REPORT_REQ,
  FETCH_REPORT_RES,
} from './types';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import credentials from '../config/credentials';

export const signin =
  ({ email, password }, callback) =>
  async (dispatch) => {
    if (
      credentials[email] &&
      bcrypt.compareSync(password, credentials[email])
    ) {
      dispatch({ type: AUTH_USER, payload: credentials[email] });
      localStorage.setItem('token', credentials[email]);
      callback();
    } else {
      dispatch({ type: AUTH_ERROR, payload: 'Invalid login credentials' });
    }
  };

export const signout = () => {
  localStorage.removeItem('token');

  return {
    type: AUTH_USER,
    payload: '',
  };
};

export const fetchOrders = (start) => async (dispatch) => {
  dispatch({ type: FETCH_ORDERS_REQ });

  const orders = await axios
    .get(`/api/orders?start=${start}`)
    .then((res) => res.data);

  dispatch({
    type: FETCH_ORDERS_RES,
    payload: orders,
  });
};

export const fetchReport = (start, end) => async (dispatch) => {
  dispatch({ type: FETCH_REPORT_REQ });

  const orders = await axios
    .get(`/api/orders?start=${start}&end=${end}`)
    .then((res) => res.data);

  dispatch({
    type: FETCH_REPORT_RES,
    payload: orders,
  });
};

export const toggleSidenav = (status) => {
  return {
    type: TOGGLE_SIDENAV,
    payload: status,
  };
};

export const receiveWebSocketOrders = (orders) => {
  return {
    type: RECEIVE_WS_ORDERS,
    payload: orders,
  };
};

export const updateOrderCompletedTime = (order) => {
  if (order.completedTime) {
    order.completedTime = null;
  } else {
    const time = new Date();
    order.completedTime = time.toISOString();
  }

  axios.put(`/api/orders/${order._id}/completed-time`, {
    completedTime: order.completedTime,
  });

  return {
    type: UPDATE_ORDER_COMPLETED_TIME,
    payload: order,
  };
};

export const updateLineBrewReadyTime = (orderId, line) => {
  if (line.brewReadyTime) {
    line.brewReadyTime = null;
  } else {
    const time = new Date();
    line.brewReadyTime = time.toISOString();
  }

  axios.put(`/api/orders/${orderId}/${line.id}/brew-ready-time`, {
    brewReadyTime: line.brewReadyTime,
  });

  return {
    type: UPDATE_LINE_BREW_READY_TIME,
    payload: { orderId, line },
  };
};

export const updateLineBrewUsedTime = (orderId, line) => {
  if (line.brewUsedTime) {
    line.brewUsedTime = null;
  } else {
    const time = new Date();
    line.brewUsedTime = time.toISOString();
  }

  axios.put(`/api/orders/${orderId}/${line.id}/brew-used-time`, {
    brewUsedTime: line.brewUsedTime,
  });

  return {
    type: UPDATE_LINE_BREW_USED_TIME,
    payload: { orderId, line },
  };
};

let timer = null;
const tick = () => ({ type: TIMER_TICK });

export const startTimer = () => (dispatch) => {
  clearInterval(timer);
  timer = setInterval(() => dispatch(tick()), 1000);
  dispatch({ type: TIMER_START });
  dispatch(tick());
};

export const stopTimer = () => {
  clearInterval(timer);
  return { type: TIMER_STOP };
};
