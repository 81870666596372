import React, { Component } from 'react';
import { connect } from 'react-redux';

class BrewUsedTimer extends Component {
  shouldComponentUpdate(nextProps) {
    return !nextProps.brewUsedTime;
  }

  render() {
    const { brewUsedTime, brewReadyTime, curTime } = this.props;
    if (!brewReadyTime) {
      return <div></div>;
    } else {
      return (
        <div>
          {brewUsedTime
            ? (
                (new Date(brewUsedTime) - new Date(brewReadyTime).getTime()) /
                (60 * 1000)
              ).toFixed(2)
            : (
                (curTime - new Date(brewReadyTime).getTime()) /
                (60 * 1000)
              ).toFixed(2)}{' '}
          mins
        </div>
      );
    }
  }
}

function mapStateToProps({ curTime }) {
  return {
    curTime,
  };
}

export default connect(mapStateToProps)(BrewUsedTimer);
