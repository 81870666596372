import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleSidenav } from '../actions';

class Sidenav extends Component {
  renderLinks() {
    const authedLinks = [
      { link: '/report-search', desc: 'Report' },
      { link: '/brew-list', desc: 'Brew' },
      { link: '/order-list', desc: 'Orders' },
      { link: '/signout', desc: 'Sign Out' },
    ];

    if (this.props.authenticated) {
      let key = 1;

      return authedLinks.map(({ link, desc }) => {
        return (
          <li key={key++}>
            <Link
              onClick={() => this.props.toggleSidenav(!this.props.sidenav)}
              to={link}
            >
              {desc}
            </Link>
          </li>
        );
      });
    } else {
      return (
        <li>
          <Link
            key="0"
            to="/signin"
            onClick={() => this.props.toggleSidenav(!this.props.sidenav)}
          >
            Sign In
          </Link>
        </li>
      );
    }
  }

  render() {
    return (
      <div>
        <div
          className="sidenav-overlay"
          onClick={() => this.props.toggleSidenav(!this.props.sidenav)}
          style={
            this.props.sidenav
              ? {
                  display: 'block',
                  zIndex: 999,
                  opacity: 1,
                  visibility: 'visible',
                  transition: 'opacity 0.5s visibility 0.5s',
                }
              : {
                  display: 'none',
                  opacity: 0,
                  visibility: 'hidden',
                  transition: 'opacity 0.5s visibility 0.5s',
                }
          }
        />
        <ul
          className="sidenav"
          onClick={() => this.props.toggleSidenav(!this.props.sidenav)}
          style={
            this.props.sidenav
              ? { transform: 'translateX(0%)', transition: '0.5s' }
              : { transform: 'translateX(-105%)', transition: '0.5s' }
          }
        >
          {this.renderLinks()}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated, sidenav: state.sidenav };
}

export default connect(mapStateToProps, { toggleSidenav })(Sidenav);
