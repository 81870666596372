import React, { Component } from 'react';
import { connect } from 'react-redux';

class BrewReadyTimer extends Component {
  shouldComponentUpdate(nextProps) {
    return !nextProps.brewReadyTime;
  }

  render() {
    const { brewReadyTime, createdTime, curTime } = this.props;
    return (
      <div>
        {brewReadyTime
          ? (
              (new Date(brewReadyTime) - new Date(createdTime).getTime()) /
              (60 * 1000)
            ).toFixed(2)
          : ((curTime - new Date(createdTime).getTime()) / (60 * 1000)).toFixed(
              2
            )}{' '}
        mins
      </div>
    );
  }
}

function mapStateToProps({ curTime }) {
  return {
    curTime,
  };
}

export default connect(mapStateToProps)(BrewReadyTimer);
