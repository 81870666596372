import React, { Component } from 'react';
import BrewReadyTimer from './BrewReadyTimer';
import BrewUsedTimer from './BrewUsedTimer';
import { connect } from 'react-redux';
import { updateLineBrewReadyTime, updateLineBrewUsedTime } from '../actions';

class BrewCard extends Component {
  constructor(props) {
    super(props);
    this.handleOnClickFuc = this.handleOnClickFuc.bind(this);
    this.handleOnClickLeftFuc = this.handleOnClickLeftFuc.bind(this);
  }

  handleOnClickFuc() {
    const { orderId, line } = this.props;
    if (!line.brewReadyTime) {
      this.props.updateLineBrewReadyTime(orderId, line);
    } else {
      this.props.updateLineBrewUsedTime(orderId, line);
    }
  }

  handleOnClickLeftFuc() {
    const { orderId, line } = this.props;
    this.props.updateLineBrewReadyTime(orderId, line);
  }

  render() {
    const {
      title,
      line,
      size,
      hasLeftButton,
      rightIcon,
      createdTime,
      brewReadyTime,
      brewUsedTime,
    } = this.props;

    return (
      <div className="card black">
        <div className="card-content white-text left-align">
          <div className="card-header">
            <div className="card-title">ORDER {title}</div>
            <div className="brew-times">
              <BrewReadyTimer
                brewReadyTime={brewReadyTime}
                createdTime={createdTime}
              />
              <BrewUsedTimer
                brewUsedTime={brewUsedTime}
                brewReadyTime={brewReadyTime}
              />
            </div>
          </div>
          {hasLeftButton ? (
            <div className="btn-floating btn-medium fab-left grey">
              <i
                className="material-icons right"
                onClick={this.handleOnClickLeftFuc}
              >
                remove
              </i>
            </div>
          ) : (
            ''
          )}
          <button className="btn-floating btn-medium fab grey">
            <i className="material-icons right" onClick={this.handleOnClickFuc}>
              {rightIcon}
            </i>
          </button>
          <ul>
            <li>{line.name}</li>
            <li className="indent">{size}</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  updateLineBrewReadyTime,
  updateLineBrewUsedTime,
})(BrewCard);
